"use client";
import { useContext } from "react";
import PopupAction from "../popup-action/popup-action";
import { Image } from "../image/image";
import ButtonAction from "../button-action/button-action";
import { useIsDesktop } from "@/hooks/use-is-mobile";
import { urlAsset } from "@/helper/utils";
import { PopupMaintenanceContext } from "./popup-maintenance-provider";

const PopupMaintenance = () => {
  const brandName = process.env.NEXT_PUBLIC_NAME;
  const isDesktop = useIsDesktop();
  const { showPopup, setShowPopup } = useContext(PopupMaintenanceContext);

  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <PopupAction
      className={"lg:max-w-[360px] !bg-bg-3"}
      padding={isDesktop ? "p-[15px]" : "p-[10px]"}
      isShowing={showPopup}
      onClickBtnX={() => handleClose()}
      href="#"
      zIndex="z-30"
    >
      <div className="w-full h-full p-[15px] rounded-[10px]">
        <div className="overflow-hidden h-full relative aspect-[304/177] rounded-[15px] bg-bg-1">
          <Image
            alt="Maintenance"
            src={`${urlAsset()}/404/maintenance.png`}
            className="py-[10px] px-[17px]"
          />
        </div>
        <div className="my-[20px] max-w-[304px] text-text-2 flex flex-col gap-[10px]">
          <p className="text-xl font-black font-font-family-8 capitalize">
            Website Sedang dalam Masa Pemeliharaan
          </p>
          <p className="text-center font-font-family-5 lg:font-medium lg:font-font-family-6">
            Kami sedang melakukan pemeliharaan situs agar dapat memberikan
            pengalaman yang lebih optimal. Silahkan login/regis kembali nanti.{" "}
            <i>Stay tuned!</i>
          </p>
        </div>
        <ButtonAction
          data-testid="popup-cancel"
          onClick={() => handleClose()}
          className="py-[13px] px-[20px]"
          intent="primary"
        >
          Tutup
        </ButtonAction>
      </div>
    </PopupAction>
  );
};

export default PopupMaintenance;
